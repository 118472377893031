import { useMemo } from "react";
import { loyaltyTabs } from "../../libs/utils/constants";
import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";

export const useFilteredLoyaltyTabs = () => {
  const organization = useCurrentOrganization();
  const currencyList = organization.shopifyStoreData?.enabled_presentment_currencies;
  return useMemo(() => {
    let filteredTabs = loyaltyTabs;
    const effectiveCurrencyList = currencyList ?? [];
    if (effectiveCurrencyList.length <= 1) {
      filteredTabs = filteredTabs.filter(tab => tab.id !== "currencies");
    }
    return filteredTabs;
  }, [currencyList, loyaltyTabs]);
};
