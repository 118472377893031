import { Button, Divider, TextField, VerticalStack } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import React from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { LoyaltyStoreAccess } from "../../../store";
import { getEventNamesBasedOnType } from "../../../libs/utils/constants";
import { displayError } from "../../../libs/utils/displayError";
import { LoyaltyEventsHeader } from "../LoyaltyEventsHeader";
import { LoyaltyActiveSubscriberMultiplier } from "../LoyaltyActiveSubscriberMultiplier";

const EventContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 8px;

  & > div:nth-of-type(1) {
    width: 40%;
  }
  & > div:nth-of-type(2) {
    width: 50%;
  }
  & > div:nth-of-type(3) {
    align-self: baseline;
    justify-content: center;
    margin-top: 6px;
    width: 10%;
  }
`;

const MultiplierContainer = styled.div`
  margin-top: 16px;

  .Polaris-Divider {
    margin-bottom: 16px;
  }
`;

export const LoyaltyEvents = (): JSX.Element => {
  const events = LoyaltyStoreAccess.useValue("events");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();
  const { symbol: currencySymbol } = LoyaltyStoreAccess.useCurrency();
  const { addToast } = useToast();

  return (
    <VerticalStack gap={"1"}>
      <LoyaltyEventsHeader />
      {events.length > 0 ? (
        <EventContainer>
          <div>Event</div>
          <div>Value</div>
          <div />
        </EventContainer>
      ) : null}
      {events.map((event, index) => (
        <EventContainer key={index}>
          <TextField
            label="Event"
            value={getEventNamesBasedOnType(event.type, currencySymbol)}
            autoComplete="off"
            labelHidden
          />
          <TextField
            type="number"
            min={0}
            label="Value"
            value={String(event.pointsEarned)}
            autoComplete="off"
            labelHidden
            suffix={pluralizeForPrefix(event.pointsEarned, "point")}
            onChange={newValue =>
              actions.events.updateEvent({
                index,
                points: Number(newValue),
              })
            }
            error={displayError(LoyaltyValidation.Event.schema.shape.pointsEarned, event.pointsEarned, showError)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            data-testid={"smartrr-test-loyalty-events-delete"}
          >
            <Button
              plain
              icon={DeleteMajor}
              onClick={() => {
                if (events.length > 1) {
                  actions.events.removeEvent(index);
                } else {
                  addToast("At least one event required");
                }
              }}
            ></Button>
          </div>
        </EventContainer>
      ))}
      <MultiplierContainer>
        <Divider />
        <LoyaltyActiveSubscriberMultiplier />
      </MultiplierContainer>
    </VerticalStack>
  );
};
