import { Checkbox, Tooltip, VerticalStack } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "../../../store";
import { NumberedTextField } from "@vendor-app/app/_sharedComponents/NumberedTextField";
import { useEffect, useState } from "react";
import { isNull } from "lodash";
import { displayError } from "../../../libs/utils/displayError";
import { LoyaltyForm } from "../../../store/form";
import styled from "styled-components";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

const ActiveMultiplierEditField = styled.div`
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: unset;
  }
`;

export const LoyaltyActiveSubscriberMultiplier = () => {
  const activeSubscriberMultiplier = LoyaltyStoreAccess.useValue("activeSubscriberMultiplier");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();
  const flags = useSmartrrFlags();
  const { events, otpEnabled } = LoyaltyStoreAccess.useInput();

  const [nonNullValue, setNonNullValue] = useState<number>(1);
  const billingSuccess = events.find(event => event.type === "BILLING_SUCCESS");
  const checkboxEnabled = otpEnabled && billingSuccess;
  const tooltipText = otpEnabled
    ? "Subscriber multiplier can only be selected when transaction per spend event is enabled."
    : 'Multiplier is only possible if "Loyalty Availability" is set to "All customers"';

  useEffect(() => {
    if (activeSubscriberMultiplier) {
      setNonNullValue(activeSubscriberMultiplier);
    }
  }, [activeSubscriberMultiplier]);

  if (!flags.activeSubscriberRewardsMultiplier) {
    return null;
  }

  return (
    <VerticalStack>
      {checkboxEnabled ? (
        <Checkbox
          label="Add multiplier for customers with active subscriptions"
          checked={activeSubscriberMultiplier !== null}
          disabled={!checkboxEnabled}
          onChange={checked => {
            actions.activeSubscriberMultiplier.update(checked ? nonNullValue : null);
          }}
        />
      ) : (
        <Tooltip content={tooltipText}>
          <Checkbox
            label="Add multiplier for customers with active subscriptions"
            checked={activeSubscriberMultiplier !== null}
            disabled={!checkboxEnabled}
            onChange={checked => {
              actions.activeSubscriberMultiplier.update(checked ? nonNullValue : null);
            }}
          />
        </Tooltip>
      )}

      {isNull(activeSubscriberMultiplier) ? null : (
        <ActiveMultiplierEditField>
          <NumberedTextField
            label="Multiplier"
            suffix="X points"
            value={activeSubscriberMultiplier}
            autoComplete="off"
            min={1}
            onChange={val => actions.activeSubscriberMultiplier.update(Number(val))}
            error={displayError(
              LoyaltyForm.Program.schema.shape.activeSubscriberMultiplier,
              activeSubscriberMultiplier,
              showError
            )}
          />
        </ActiveMultiplierEditField>
      )}
    </VerticalStack>
  );
};
